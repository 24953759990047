// Testimonial.tsx
import React, { useEffect, useRef, useState } from 'react';
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation';
import * as styles from './Testimonial.module.css';

type ColorType = 'primary' | 'secondary';

interface TestimonialItem {
  imageSrc: string;
  name: string;
  content: string;
}

interface TestimonialProps {
  TestimonialItems: TestimonialItem[];
  colorType: ColorType;
}

const Testimonial: React.FC<TestimonialProps> = ({
  TestimonialItems,
  colorType,
}) => {
  let SectionClass = styles.sectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    SectionClass = styles.sectionPrimary;
  } else if (colorType === 'secondary') {
    SectionClass = styles.sectionSecondary;
  }
  return (
    <ScrollAnimation>
      <section className={SectionClass}>
        <div className={styles.testimonialsWrappar}>
          {TestimonialItems.map((item, index) => (
            <div key={index} className={styles.testimonialContainer}>
              <img
                src={item.imageSrc}
                alt={item.name}
                className={styles.testimonialImage}
              />
              <div className={styles.testimonialName}>{item.name}</div>
              <div className={styles.testimonialContent}>"{item.content}"</div>
            </div>
          ))}
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default Testimonial;

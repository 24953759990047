// extracted by mini-css-extract-plugin
export var addToCartButton = "ProductList-module--addToCartButton--44d89";
export var productDescription = "ProductList-module--productDescription--d2c61";
export var productImage = "ProductList-module--productImage--4fffd";
export var productItem = "ProductList-module--productItem--b8256";
export var productList = "ProductList-module--productList--d7926";
export var productName = "ProductList-module--productName--087e3";
export var productPrice = "ProductList-module--productPrice--adbf0";
export var productSection = "ProductList-module--productSection--4baf5";
export var sectionPrimary = "ProductList-module--sectionPrimary--53e07";
export var sectionSecondary = "ProductList-module--sectionSecondary--eb349";
import * as React from 'react';
import Accordion from '../components/Accordion/Accordion';
import FetureSectionGridThree from '../components/FeatureSectionGridThree/FetureSectionGridThree';
import FeatureSectionLeft from '../components/FeatureSectionLeft/FeatureSectionLeft';
import FeatureSectionRight from '../components/FeatureSectionRight/FeatureSectionRight';
import HeroCarousel from '../components/HeroCarousel/HeroCarousel';
import ProductList from '../components/ProductList/ProductList';
import Testimonial from '../components/Testimonial/Testimonial';
import Layout from '../components/layout';
import Seo from '../components/seo';

const FoodTest = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <HeroCarousel
          slides={[
            {
              id: '1',
              image: '/Teddyメイン画像1.png',
            },
            {
              id: '2',
              image: '/Teddyメイン画像2.png',
            },
            {
              id: '3',
              image: '/Teddyメイン画像3.png',
            },
          ]}
        />
        <main>
          <FeatureSectionLeft
            imageSrc="/teddy001.jpg"
            imageAlt="Example image"
            title="Teddyとは"
            subtitle="ABOUT TEDDY"
            description="Teddyは、北九州小倉のステーキハウス「肉のながおか」が提供する
            高級和牛を使用して作られたドッグフードです
            「大切な愛犬にも、美味しいご飯を食べて欲しい」そんな思いから
            厳選した国産牛の良さを活かした、豊富な栄養を含むドッグフードに仕上げました。
            ながおかの高級ドッグフード「Teddy」を
            愛するペットへの特別なご褒美として、食べさせてみてはいかがでしょうか"
            sectionId="about"
          />
          <FeatureSectionRight
            colorType="secondary"
            imageSrc="/nagaoka-teddy.jpg"
            imageAlt="Example image"
            title="肉のながおか"
            subtitle="ABOUT US"
            description="北九州市小倉北区にあるステーキハウス「肉のながおか」は、代表厳選の
            黒毛和牛を活かしたコース料理やワインが楽しめるオトナの隠れ家です"
            sectionId="nagaoka"
          />
          <FetureSectionGridThree
            title="Teddyのこだわり"
            subtitle="FEATURES"
            sectionId="feature"
            cords={[
              {
                imageSrc: '/f101.jpg',
                imageAlt: 'Example image',
                title: 'こだわりの素材',
                description: `Teddyは、弊社代表が厳選した国産牛を活用して作られたドッグフードです。
                タンパク質やビタミンなど、栄養素が豊富に含まれる国産牛を、小型犬から大型犬まで消化しやすいよう加工しております。。`,
              },
              {
                imageSrc: '/f102.jpg',
                imageAlt: 'Example image',
                title: '豊富な栄養素',
                description: `Teddyは無添加・自然食材を使用しているため、豊富な栄養分が含まれます。
                  タンパク質やビタミンなど、愛犬の健康維持に欠かせない養分を摂取させてあげられます。`,
              },
              {
                imageSrc: '/f103.jpg',
                imageAlt: 'Example image',
                title: '無添加・無着色',
                description: `Teddyのドッグフードは、無添加・無着色の素材を使用して作られています。
                遺伝子組み換え食品や抗生物質不使用の食材を使用しているため、消化における負担を大幅に和らげられます。`,
              },
            ]}
          />
          <ProductList
            colorType="secondary"
            products={[
              {
                id: '1',
                name: 'アキレス',
                description: `60g×2袋`,
                price: 3000,
                imageUrl: '/アキレス.jpg',
                linkUrl:
                  'https://jp.mercari.com/shops/product/VsaCrtm4i4WHwscf9zEcqC?source=shop_page_section_view_all&_gl=1*ln9ntd*_gcl_au*MTY2Mjg4MjE1MC4xNzEzOTE5NDQz*_ga*MTYzNDQzNzEyMS4xNzEzOTE5NDQy*_ga_4NLR7T2LEN*MTcxNzA3MzA4My4zLjEuMTcxNzA3MzE2OS42MC4wLjA.', // 商品のリンク先を追加
              },
              {
                id: '2',
                name: 'レバー',
                description: '50g×4袋',
                price: 2500,
                imageUrl: '/レバー.jpg',
                linkUrl:
                  'https://jp.mercari.com/shops/product/V9TkjjRZ5C5eEHxa4anC7e?source=shop_page_section_view_all&_gl=1*16byoyl*_gcl_au*MTY2Mjg4MjE1MC4xNzEzOTE5NDQz*_ga*MTYzNDQzNzEyMS4xNzEzOTE5NDQy*_ga_4NLR7T2LEN*MTcxNzA3MzA4My4zLjEuMTcxNzA3MzM5Mi42MC4wLjA.', // 商品のリンク先を追加
              },
              {
                id: '3',
                name: '牛タン',
                description: '30g×4袋',
                price: 2600,
                imageUrl: '/牛タン.jpg',
                linkUrl:
                  'https://jp.mercari.com/shops/product/terT8aUiQ9hJEEoqQJR5Uk?source=shop_page_section_view_all&_gl=1*16byoyl*_gcl_au*MTY2Mjg4MjE1MC4xNzEzOTE5NDQz*_ga*MTYzNDQzNzEyMS4xNzEzOTE5NDQy*_ga_4NLR7T2LEN*MTcxNzA3MzA4My4zLjEuMTcxNzA3MzM5Mi42MC4wLjA.', // 商品のリンク先を追加
              },
              {
                id: '4',
                name: 'ラング（肺)',
                description: '30g×4袋',
                price: 2000,
                imageUrl: '/teddy/ラング(肺).jpg',
                linkUrl:
                  'https://jp.mercari.com/shops/product/iy4rtnzf3oaotBaVga3M8J?source=shop_page_section&_gl=1*fdl8x3*_gcl_au*MTY2Mjg4MjE1MC4xNzEzOTE5NDQz*_ga*MTYzNDQzNzEyMS4xNzEzOTE5NDQy*_ga_4NLR7T2LEN*MTcxODcxNjA0NC42LjEuMTcxODcxNjExMS42MC4wLjA.', // 商品のリンク先を追加
              },
              {
                id: '5',
                name: '鶏胸肉',
                description: '50g×4袋',
                price: 1800,
                imageUrl: '/teddy/鶏胸肉.jpg',
                linkUrl:
                  'https://jp.mercari.com/shops/product/SY4LRFRxGM6ppanmuujyAP?source=shop_page_section_view_all&_gl=1*1jhrfj7*_gcl_au*MTY2Mjg4MjE1MC4xNzEzOTE5NDQz*_ga*MTYzNDQzNzEyMS4xNzEzOTE5NDQy*_ga_4NLR7T2LEN*MTcxODcxNjA0NC42LjEuMTcxODcxNjE5NC42MC4wLjA.', // 商品のリンク先を追加
              },
              {
                id: '6',
                name: '国産牛赤身100%',
                description: '100g×4袋',
                price: 3200,
                imageUrl: '/teddy/赤身.jpg',
                linkUrl:
                  'https://jp.mercari.com/shops/product/cT8rvMCBi7KUoX2vNZ3Kgh?source=shop_page_section_view_all&_gl=1*5pmke5*_gcl_au*MTY2Mjg4MjE1MC4xNzEzOTE5NDQz*_ga*MTYzNDQzNzEyMS4xNzEzOTE5NDQy*_ga_4NLR7T2LEN*MTcxODcxNjA0NC42LjEuMTcxODcxNjIyNy4yNy4wLjA.', // 商品のリンク先を追加
              },
            ]}
          />
          <Testimonial
            colorType="primary"
            TestimonialItems={[
              {
                imageSrc: '/r103.svg',
                name: 'しむはるさん(東京都在住)',
                content: `食欲に波のある子でしたが、Teddyのドッグフードを食べてからガツガツ完食してくれるようになりました！無添加・自然食材使用のため安心して与えられます。`,
              },
              {
                imageSrc: '/r104.svg',
                name: 'R.Hさん(宮城県在住）',
                content: `老犬でもあっという間に完食しました！犬にとってはとても食べやすい様でした。
                運動量の多いわんちゃんには特におすすめできると思います。`,
              },
            ]}
          />
          <Accordion
            title="よくある質問"
            subtitle="FAQ"
            sectionId="accordion"
            colorType="primary"
            items={[
              {
                title: 'どのような犬種に適していますか？',
                content: `Teddyのドッグフードは、大型犬から小型犬まであらゆる犬種に適しています。
                  体格や年齢に関係なくお召し上がりいただけます。`,
              },
              {
                title: 'どんな味がするのでしょうか？',
                content: `厳選した国産牛を使用して作られたTeddyのドッグフードは、高級ステーキのような味わいが特徴です。
                肉の旨み・豊富な栄養を含むドッグフードを愛犬に食べさせてあげましょう。`,
              },
              {
                title: 'どんな栄養素が含まれているのでしょうか？',
                content: `Teddyのドッグフードには、たんぱく質や必須脂肪酸、ビタミンやミネラルなど、愛犬の健康をサポートする栄養素がバランスよく含まれています。
                  愛犬の健康を維持するため、厳選された成分が使用されています。`,
              },
              {
                title: 'どうやって保存すれば良いでしょうか？',
                content: `直射日光や高温多湿を避けつつ、袋をしっかりと閉じて涼しい場所で保管し、開封後は賞味期限内でもお早めにご使用ください。`,
              },
              {
                title: '配送料金はどれくらいでしょうか？',
                content: `配送料金は地域や購入数量によって異なります。
                  詳細についてはご購入時にお問い合わせいただくか、公式ウェブサイトでご確認ください。`,
              },
            ]}
          />
        </main>
      </Layout>
    </div>
  );
};

export default FoodTest;

export const Head = () => (
  <Seo
    title={`Teddy｜肉のながおかが厳選した高級和牛のドッグフード`}
    description={`Teddyは国産牛を活用し、無添加・自然食材にこだわったドッグフードです。
    豊富な栄養素を含みヒューマングレードであるTeddyを、日々のご褒美として愛犬に食べさせてあげましょう。`}
    keywords={[
      'Teddy',
      '肉のながおか',
      'ドッグフード',
      '高級和牛',
      '無添加',
      '自然食材',
    ]}
    cover={'/f101.jpg'}
  />
);

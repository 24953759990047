// Footer.tsx
import React from 'react';
import * as styles from './Footer.module.css';

const Footer = ({
  items,
  logo,
  logoAltText,
  companyName,
  address,
  phoneNumber,
  faxNumber,
  copyrightText,
  logoLinkText,
}) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.mdFlex + ' ' + styles.mdJustifyBetween}>
          <div>
            <a href={logoLinkText} className={styles.footerLogo}>
              <img src={logo} width="140" height="20" alt={logoAltText} />
            </a>
            <address className={styles.footerAddress}>
              {companyName}
              <br />
              {address}
              <br />
              TEL：<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
              {faxNumber ? `/ FAX：${faxNumber}` : ''}
              <br />
            </address>
          </div>
          <div className={styles.grid}>
            {items.map((item) => (
              <div key={item.categoriesname}>
                <p className={styles.footerNaviHeading}>
                  {item.categoriesname}
                </p>
                <ul className={styles.footerNavi}>
                  {item.categories.map((category) => (
                    <li key={category.name}>
                      <a href={category.link}>{category.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
      <hr />
      <div className={styles.footerWrapper}>
        <p>
          © {new Date().getFullYear()}{' '}
          <a href={logoLinkText}>{copyrightText}</a>
          All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import Footer from '../components/Footer5/Footer';
import Header from '../components/Header101/Header';

const Layout = ({ children }) => {
  return (
    <div>
      <Header
        title="Teddyペットフード"
        logoImage="/teddylogo.svg"
        navItems={[
          {
            id: 2,
            name: 'Teddyとは',
            dropdown: false,
            link: '/#about',
          },
          {
            id: 3,
            name: '肉のながおか',
            dropdown: true,
            link: 'https://niku-nagaoka.com',
            subItems: [
              {
                name: '肉のながおか公式サイト',
                link: 'https://niku-nagaoka.com',
              },
              {
                name: 'お肉ECサイト',
                link: 'https://nikunagaoka.base.shop',
              },
              {
                name: 'LINE公式',
                link: 'https://page.line.me/512pfivb?openQrModal=true',
              },
            ],
          },
          {
            id: 4,
            name: 'teddyのこだわり',
            link: '/#feature',
            dropdown: false,
          },
          {
            id: 5,
            name: 'よくある質問',
            dropdown: false,
            link: '/#accordion',
          },
        ]}
      />
      {children}
      <Footer
        items={[
          {
            categoriesname: '肉のながおかについて',
            categories: [
              {
                name: '肉のながおか公式サイト',
                link: 'https://niku-nagaoka.com',
              },
              {
                name: '肉のながおかECサイト',
                link: 'https://nikunagaoka.base.shop',
              },
              {
                name: '肉のながおかLINE公式',
                link: 'https://page.line.me/512pfivb?openQrModal=true',
              },
            ],
          },
          {
            categoriesname: 'Teddyについて',
            categories: [
              {
                name: 'TeddyECサイト',
                link: 'https://mercari-shops.com/shops/DwHFP2pAFSSgiwnM3TjAr7/sections/products/FRt462iomLNfVbXuBWjh7e',
              },
              { name: 'Teddyのこだわり', link: '/#feature' },
              { name: 'コラム一覧', link: '/pages' },
            ],
          },
          {
            categoriesname: '弊社について',
            categories: [{ name: 'プライバシーポリシー', link: '/privacy' }],
          },
        ]}
        logo="/logo.png"
        logoAltText="teddy Logo"
        companyName="肉のながおか"
        address="〒802-0083 福岡県北九州市小倉北区江南町４−２"
        phoneNumber="093-953-8242"
        copyrightText="肉のながおか."
        logoLinkText="https://niku-nagaoka.com/"
      />
    </div>
  );
};

export default Layout;

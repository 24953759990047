// src/components/ProductList.tsx
import React from 'react';
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation';
import * as styles from './ProductList.module.css';

type ColorType = 'primary' | 'secondary';

type Product = {
  id: string;
  name: string;
  description: string;
  price: number;
  imageUrl: string;
  linkUrl: string; // リンク先URLを追加
};

interface ProductListProps {
  products: Product[];
  colorType: ColorType;
}

const ProductList: React.FC<ProductListProps> = ({ products, colorType }) => {
  let SectionClass = styles.sectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    SectionClass = styles.sectionPrimary;
  } else if (colorType === 'secondary') {
    SectionClass = styles.sectionSecondary;
  }
  return (
    <ScrollAnimation>
      <section className={SectionClass}>
        <div className={styles.productList}>
          {products.map((product) => (
            <div key={product.id} className={styles.productItem}>
              <img
                src={product.imageUrl}
                alt={product.name}
                className={styles.productImage}
              />
              <h2 className={styles.productName}>{product.name}</h2>
              <p className={styles.productDescription}>{product.description}</p>
              <p className={styles.productPrice}>
                {product.price.toFixed(0)}円
              </p>
              <a
                href={product.linkUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className={styles.addToCartButton}>購入ページへ</button>
              </a>
            </div>
          ))}
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default ProductList;

// src/components/FetureSectionGridThree/FetureSectionGridThree.tsx

import React from 'react';
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation';
import * as styles from './FeatureSection.module.css';

type ColorType = 'primary' | 'secondary';

type Cord = {
  imageSrc: string;
  imageAlt: string;
  title: string;
  description: string;
};

type FeatureSectionProps = {
  title: string;
  colorType: ColorType;
  cords: Cord[];
  sectionId: string;
  subtitle: string;
};

const FetureSectionGridThree: React.FC<FeatureSectionProps> = ({
  title,
  colorType,
  cords,
  sectionId,
  subtitle,
}) => {
  let featureSectionClass = styles.featureSectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    featureSectionClass = styles.featureSectionPrimary;
  } else if (colorType === 'secondary') {
    featureSectionClass = styles.featureSectionSecondary;
  }
  return (
    <ScrollAnimation>
      <section className={featureSectionClass} id={sectionId}>
        <div className={styles.featureSectionInner}>
          <h2 className={styles.head}>
            <span className={styles.headText}>{title}</span>
            <span className={styles.headSubtext}>{subtitle}</span>
          </h2>
          <div className={styles.grid}>
            {cords.map((cord, index) => (
              <div key={index} className={styles.cord}>
                <img
                  className={styles.image}
                  src={cord.imageSrc}
                  alt={cord.imageAlt}
                />
                <div className={styles.content}>
                  <h3 className={styles.title}>{cord.title}</h3>
                  <p className={styles.description}>{cord.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default FetureSectionGridThree;
